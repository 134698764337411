import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import PublicLayout from "./components/PublicLayout";
import Home from "./components/Home";
import DetailPage from "./components/DetailPage";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AddArticle from "./components/AddArticle";
import ListArticles from "./components/ListArticles";
import AddCategory from "./components/AddCategory";
import ListCategories from "./components/ListCategories";
import AddUser from "./components/AddUser";
import ListOfUsers from "./components/ListOfUsers";
import History from "./components/History";
import NotFound from "./components/404";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import MainLayout from "./components/MainLayout";

function App() {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <PublicLayout>
            <Home />
          </PublicLayout>
        }
      />
      <Route
        path="/:id"
        element={
          <PublicLayout>
            <DetailPage />
          </PublicLayout>
        }
      />
      <Route
        path="/login"
        element={
          <PublicLayout>
            <PublicRoute>
              <Login />
            </PublicRoute>
          </PublicLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/articles/add"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddArticle />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/articles/edit/:id"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddArticle />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/articles/list"
        element={
          <MainLayout>
            <ProtectedRoute>
              <ListArticles />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/categories/add"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddCategory />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/categories/edit/:id"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddCategory />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/categories/list"
        element={
          <MainLayout>
            <ProtectedRoute>
              <ListCategories />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/users/add"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/users/edit/:id"
        element={
          <MainLayout>
            <ProtectedRoute>
              <AddUser />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/users/list"
        element={
          <MainLayout>
            <ProtectedRoute>
              <ListOfUsers />
            </ProtectedRoute>
          </MainLayout>
        }
      />
      <Route
        path="/history"
        element={
          <MainLayout>
            <ProtectedRoute>
              <History />
            </ProtectedRoute>
          </MainLayout>
        }
      />
    </Routes>
  );
}

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
