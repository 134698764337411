import axios from "axios";
import API_URL from "../config";

const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/users/login`, {
    login: username,
    password,
  });
  if (response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
    setAuthToken(response.data.token);
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
  setAuthToken(null);
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getToken = () => {
  const user = getCurrentUser();
  return user ? user.token : null;
};

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const initialToken = getToken();
if (initialToken) {
  setAuthToken(initialToken);
}

export default {
  login,
  logout,
  getCurrentUser,
  getToken,
};
