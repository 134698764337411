import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import articleService from "../services/articleService";
import "../styles/ListArticles.css";
import API_URL from "../config";

const ListArticles = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await articleService.getArticles();
        setArticles(response.data.articles);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleDelete = async (id) => {
    try {
      await articleService.deleteArticle(id);
      setArticles(articles.filter((article) => article.id !== id));
      alert("Article deleted successfully");
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/articles/edit/${id}`);
  };

  return (
    <div className="list-articles-container">
      <h1>Articles</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Content</th>
            <th>Category</th>
            <th>Author</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {articles.length > 0 ? (
            articles.map((article) => (
              <tr key={article.id}>
                <td>{article.id}</td>
                <td>{article.title || "No title"}</td>
                <td>{article.content || "No content"}</td>
                <td>
                  {article.category ? article.category.name : "No category"}
                </td>
                <td>
                  {article.author ? article.author.username : "No author"}
                </td>
                <td>
                  {article.image ? (
                    <img
                      src={`${API_URL.replace("/api", "")}/uploads/${
                        article.image
                      }`}
                      alt={article.title}
                      width="100"
                    />
                  ) : (
                    "No image"
                  )}
                </td>
                <td>
                  <button onClick={() => handleEdit(article.id)}>Edit</button>
                  <button onClick={() => handleDelete(article.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No articles available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListArticles;
