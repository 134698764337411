import axios from "axios";
import authService from "./authService";
import API_URL from "../config";

const getArticles = () => {
  return axios.get(`${API_URL}/articles`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const getArticleById = (id) => {
  return axios.get(`${API_URL}/articles/${id}`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const addArticle = (formData) => {
  return axios.post(`${API_URL}/articles`, formData, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

const updateArticle = (id, formData) => {
  return axios.put(`${API_URL}/articles/${id}`, formData, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteArticle = (id) => {
  return axios.delete(`${API_URL}/articles/${id}`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

export default {
  getArticles,
  getArticleById,
  addArticle,
  updateArticle,
  deleteArticle,
};
