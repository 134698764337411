import React from "react";
import Sidebar from "./Sidebar";

const MainLayout = ({ children }) => (
  <div className="App">
    <Sidebar />
    <div className="main-content">{children}</div>
  </div>
);

export default MainLayout;
