import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import articleService from "../services/articleService";
import categoryService from "../services/categoryService";
import "../styles/AddArticle.css";

const AddArticle = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoryService.getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();

    if (id) {
      const fetchArticle = async () => {
        try {
          const response = await articleService.getArticleById(id);
          setTitle(response.data.title);
          setContent(response.data.content);
          setCategoryId(response.data.categoryId);
          setImage(null);
        } catch (error) {
          console.error("Error fetching article:", error);
        }
      };

      fetchArticle();
    } else {
      setTitle("");
      setContent("");
      setCategoryId("");
      setImage(null);
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("content", content);
      formData.append("categoryId", categoryId);
      if (image) {
        formData.append("image", image);
      }

      if (id) {
        await articleService.updateArticle(id, formData);
        Swal.fire({
          icon: "success",
          title: "Article updated successfully",
        });
      } else {
        await articleService.addArticle(formData);
        Swal.fire({
          icon: "success",
          title: "Article added successfully",
        });
      }
      navigate("/articles/list");
    } catch (error) {
      console.error("Error adding/updating article:", error);
      setError("Failed to add/update article. Please try again.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add/update article. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-article-container">
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Content</label>
          <div className="quill-container">
            <ReactQuill
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            required
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Processing..." : id ? "Update Article" : "Add Article"}
        </button>
      </form>
    </div>
  );
};

export default AddArticle;
