import axios from "axios";
import API_URL from "../config";

const addUser = (user) => {
  return axios.post(`${API_URL}/users/register`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getUsers = () => {
  return axios.get(`${API_URL}/users`);
};

const getUserById = (id) => {
  return axios.get(`${API_URL}/users/${id}`);
};

const updateUser = (id, user) => {
  return axios.put(`${API_URL}/users/${id}`, user, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const deleteUser = (id) => {
  return axios.delete(`${API_URL}/users/${id}`);
};

export default {
  addUser,
  getUsers,
  getUserById,
  updateUser,
  deleteUser,
};
