import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API_URL from "../config";
import Header from "./Header";
import "../styles/Home.css";

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const articlesPerPage = 10;
  const navigate = useNavigate();

  const fetchArticles = async (category, search, page = 1) => {
    try {
      const response = await axios.get(`${API_URL}/`, {
        params: { category, search, page, limit: articlesPerPage },
      });
      if (response.data && Array.isArray(response.data.articles)) {
        setArticles(response.data.articles);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
      } else {
        setArticles([]);
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
      setArticles([]);
    }
  };

  useEffect(() => {
    fetchArticles(selectedCategory, searchQuery, currentPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedCategory, searchQuery, currentPage]);

  const handleReadMore = (id) => {
    navigate(`/${id}`);
  };

  const truncateContent = (content, maxLength) => {
    const cleanContent = content.replace(/<\/?[^>]+(>|$)/g, "");
    if (cleanContent.length > maxLength) {
      return cleanContent.substring(0, maxLength) + "...";
    }
    return cleanContent;
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category.id);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Header onCategorySelect={handleCategorySelect} onSearch={handleSearch} />
      <ul className="cards">
        {articles.length === 0 && <p>No articles found</p>}
        {articles.map((article) => (
          <li className="cards__item" key={article.id}>
            <div className="card">
              <div
                className="card__image"
                style={{
                  backgroundImage: `url(${API_URL.replace(
                    "/api",
                    ""
                  )}/uploads/${article.image})`,
                }}
              ></div>
              <div className="card__content">
                <div className="card__title">{article.title}</div>
                <p className="card__text">
                  {truncateContent(article.content, 100)}
                </p>
                <button
                  className="btn btn--block card__btn"
                  onClick={() => handleReadMore(article.id)}
                >
                  Read More
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </>
  );
};

export default Home;
