import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import API_URL from "../config";
import "../styles/DetailPage.css";

const DetailPage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${API_URL}/${id}`);
        setArticle(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="detail-page">
      {article && (
        <>
          <div
            className="detail-page__image"
            style={{
              backgroundImage: `url(${API_URL.replace("/api", "")}/uploads/${
                article.image
              })`,
            }}
          ></div>
          <div className="detail-page__content">
            <h1 className="detail-page__title">{article.title}</h1>
            <p className="detail-page__author">By {article.author.username}</p>
            <div
              className="detail-page__text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(article.content),
              }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
};

export default DetailPage;
