import axios from "axios";
import authService from "./authService";
import API_URL from "../config";

const getHistory = () => {
  return axios.get(`${API_URL}/history`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

export default {
  getHistory,
};
