import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import "../styles/UserProfile.css";
import API_URL from "../config";

const UserProfile = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="user-profile">
      {user ? (
        <>
          <img
            src={`${API_URL.replace("/api", "")}/uploads/${user.user.image}`}
            alt="Profile"
            className="profile-picture"
          />
          <h2 className="username">{user.user.username}</h2>
          <p className="role">{user.user.role}</p>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default UserProfile;
