import React from "react";
import API_URL from "../config";
import "../styles/NotFound.css";

const NotFound = () => {
  const backgroundImageUrl = `${API_URL.replace("/api", "")}/uploads/404.png`;

  return (
    <div
      className="not-found"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="not-found__content">
        <h1>404 Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
