import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserProfile from "./UserProfile";
import AuthContext from "../context/AuthContext";
import "../styles/Sidebar.css";

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState("");
  const { user, logout } = useContext(AuthContext);

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? "" : menu);
  };

  return (
    <div className="sidebar">
      <UserProfile />
      <h2>
        <Link to="/dashboard">Dashboard</Link>
      </h2>
      <nav>
        <ul>
          <li onClick={() => handleMenuClick("articles")}>
            Articles
            {openMenu === "articles" && (
              <ul>
                <li>
                  <Link to="/articles/add">Add Articles</Link>
                </li>
                <li>
                  <Link to="/articles/list">List Of Articles</Link>
                </li>
              </ul>
            )}
          </li>
          {user?.user?.role === "admin" && (
            <>
              <li onClick={() => handleMenuClick("categories")}>
                Categories
                {openMenu === "categories" && (
                  <ul>
                    <li>
                      <Link to="/categories/add">Add Categories</Link>
                    </li>
                    <li>
                      <Link to="/categories/list">List Of Categories</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li onClick={() => handleMenuClick("users")}>
                Users
                {openMenu === "users" && (
                  <ul>
                    <li>
                      <Link to="/users/add">Add User</Link>
                    </li>
                    <li>
                      <Link to="/users/list">List Of Users</Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="/history">History</Link>
              </li>
            </>
          )}
        </ul>
        <ul>
          <button onClick={logout}>Logout</button>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
