import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import categoryService from "../services/categoryService";
import "../styles/AddCategory.css";

const AddCategory = () => {
  const [name, setName] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchCategory = async () => {
        try {
          const response = await categoryService.getCategoryById(id);
          setName(response.data.name);
        } catch (error) {
          console.error("Error fetching category:", error);
        }
      };

      fetchCategory();
    } else {
      setName("");
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const categoryData = { name: name };
      if (id) {
        await categoryService.updateCategory(id, categoryData);
        alert("Category updated successfully");
      } else {
        await categoryService.addCategory(categoryData);
        alert("Category added successfully");
      }
      navigate("/categories/list");
    } catch (error) {
      console.error(
        "Error adding/updating category:",
        error.response ? error.response.data : error
      );
      alert("Failed to add/update category");
    }
  };

  return (
    <div className="add-category-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Category Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit">{id ? "Update Category" : "Add Category"}</button>
      </form>
    </div>
  );
};

export default AddCategory;
