import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import "../styles/Dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Welcome</h1>
    </div>
  );
};

export default Dashboard;
