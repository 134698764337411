import React from "react";
import { Navigate } from "react-router-dom";
import authService from "../services/authService";

const PublicRoute = ({ children }) => {
  const token = authService.getToken();
  return token ? <Navigate to="/dashboard" /> : children;
};

export default PublicRoute;
