import React, { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../config";
import "../styles/Header.css";

const Header = ({ onCategorySelect, onSearch }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [categories, setCategories] = useState([]);
  const [displayCategories, setDisplayCategories] = useState([]);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_URL}/category`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const updateCategories = () => {
      const width = window.innerWidth;
      let numDisplayCategories;
      if (width >= 1025) {
        numDisplayCategories = 5;
      } else if (width >= 601 && width <= 1024) {
        numDisplayCategories = 3;
      } else {
        numDisplayCategories = 2;
      }

      if (categories.length > numDisplayCategories) {
        setDisplayCategories(categories.slice(0, numDisplayCategories));
        setHiddenCategories(categories.slice(numDisplayCategories));
      } else {
        setDisplayCategories(categories);
        setHiddenCategories([]);
      }
    };

    updateCategories();
    window.addEventListener("resize", updateCategories);

    return () => {
      window.removeEventListener("resize", updateCategories);
    };
  }, [categories]);

  const handleCategoryClick = (category) => {
    onCategorySelect(category);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="header-image">
          <img
            src={`${API_URL.replace("/api", "")}/uploads/LogoHome.png`}
            alt="Header Logo"
          />
        </div>
        <a href=".">
          <h1 className="header-title">News For You</h1>
        </a>
        <div className="header-search">
          <input
            type="text"
            placeholder="Search Box &#128269; "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <nav className="header-nav">
        <ul>
          {displayCategories.map((category, index) => (
            <li key={index}>
              <a href="#" onClick={() => handleCategoryClick(category)}>
                {category.name}
              </a>
            </li>
          ))}
          {hiddenCategories.length > 0 && (
            <li className="more">
              <a href="#" onClick={toggleDropdown}>
                More
              </a>
              {showDropdown && (
                <div className="more-dropdown">
                  {hiddenCategories.map((category, index) => (
                    <a
                      key={index}
                      href="#"
                      onClick={() => handleCategoryClick(category)}
                    >
                      {category.name}
                    </a>
                  ))}
                </div>
              )}
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
