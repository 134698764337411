import React, { useEffect, useState } from "react";
import historyService from "../services/historyService";
import "../styles/History.css";

const History = () => {
  const [histories, setHistories] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await historyService.getHistory();
        setHistories(response.data);
      } catch (error) {
        setError("Failed to fetch history");
        console.error("Error fetching history:", error);
      }
    };

    fetchHistory();
  }, []);

  return (
    <div className="history-container">
      <h2>History</h2>
      {error && <p className="error">{error}</p>}
      <table>
        <thead>
          <tr>
            <th>Action</th>
            <th>Entity</th>
            <th>Entity ID</th>
            <th>User</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {histories.map((history) => (
            <tr key={history.id}>
              <td>{history.action}</td>
              <td>{history.entity}</td>
              <td>{history.entityId}</td>
              <td>
                {history.User.username} ({history.User.email})
              </td>
              <td>{new Date(history.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default History;
