import axios from "axios";
import authService from "./authService";
import API_URL from "../config";

const CATEGORIES_API_URL = `${API_URL}/categories`;

const getCategories = () => {
  return axios.get(CATEGORIES_API_URL, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const getCategoryById = (id) => {
  return axios.get(`${CATEGORIES_API_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const addCategory = (data) => {
  return axios.post(CATEGORIES_API_URL, data, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const updateCategory = (id, data) => {
  return axios.put(`${CATEGORIES_API_URL}/${id}`, data, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

const deleteCategory = (id) => {
  return axios.delete(`${CATEGORIES_API_URL}/${id}`, {
    headers: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });
};

export default {
  getCategories,
  getCategoryById,
  addCategory,
  updateCategory,
  deleteCategory,
};
