import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import categoryService from "../services/categoryService";
import "../styles/ListCategories.css";

const ListCategories = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await categoryService.getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = async (id) => {
    try {
      await categoryService.deleteCategory(id);
      setCategories(categories.filter((category) => category.id !== id));
      alert("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/categories/edit/${id}`);
  };

  return (
    <div className="list-categories-container">
      <h1>Categories</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.length > 0 ? (
            categories.map((category) => (
              <tr key={category.id}>
                <td>{category.id}</td>
                <td>{category.name}</td>
                <td>
                  <button onClick={() => handleEdit(category.id)}>Edit</button>
                  <button onClick={() => handleDelete(category.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No categories available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ListCategories;
